import type { JSX } from "solid-js";

import styles from "~/components/FooterButtonGroup.module.scss";

export default function FooterButtonGroup(
  props: JSX.HTMLAttributes<HTMLDivElement>,
) {
  return (
    <div
      {...props}
      classList={{
        ...(props.class ? { [props.class]: true } : {}),
        ...props.classList,
        [styles.wrapper]: true,
      }}
    />
  );
}
